<template>
  <div class="fineceramics_page">
    <div class="fineceramics">
      <p class="directory">
        <span @click="homeClick" class="directory_home">Promotion Page </span> >
      </p>
      <div class="fine flex-row">
        <div class="loupe">
          <div class="imageDiv">
            <div class="small-img flex-row">
              <div class="front small-img-active" @click="clickActive" v-if="list.detailsVideo">
                <video :src="$baseUrl + list.detailsVideo" muted autoplay loop></video>
              </div>
              <div @click="clickActive" v-if="list.image">
                <img :src="$baseUrl + list.image + '?time=' + new Date().getTime()" alt="" />
              </div>
              <div v-for="(item, index) in list.detailsImage" :key="index" @click="clickActive">
                <img :src="$baseUrl + item + '?time=' + new Date().getTime()" alt="" />
              </div>
            </div>
            <div>
              <div class="big-img">
              </div>
              <div class="small_pay" style="padding-top: calc(1.04167vw);">
                <p>Payment Method:</p>
                <div>
                  <img src="@/assets/img/paymethod_1.png" />
                  <img src="@/assets/img/paymethod_2.png" />
                  <img src="@/assets/img/paymethod_3.png" />
                  <img src="@/assets/img/paymethod_4.png" />
                  <img src="@/assets/img/paymethod_5.jpg" />
                  <img src="@/assets/img/paymethod_6.jpg" />
                  <img src="@/assets/img/paymethod_7.jpg" />
                </div>
              </div>

              <div class="buy_share flex-row">
                <div class="buy_share_title">Share:</div>
                <div class="buy_share_loge">
                  <ShareNetwork network="facebook" :url="share_url" :title="share_title">
                    <img src="../../../assets/img/Facebook.png" alt="" />
                  </ShareNetwork>
                  <ShareNetwork network="Twitter" :url="share_url" :title="share_title">
                    <img src="../../../assets/img/x.png" alt="" />
                  </ShareNetwork>
                  <ShareNetwork network="whatsapp" :url="share_url" :title="share_title">
                    <img src="../../../assets/img/whats.png" alt="" />
                  </ShareNetwork>
                  <ShareNetwork network="Pinterest" :url="share_url" :title="share_title" :media="share_img">
                    <img src="../../../assets/img/pin.png" alt="" />
                  </ShareNetwork>
                  <ShareNetwork network="tiktok" :url="share_url" :title="share_title">
                    <img src="../../../assets/img/tik.png" alt="" />
                  </ShareNetwork>
                  <ShareNetwork network="instagram" :url="share_url" :title="share_title">
                    <img src="../../../assets/img/ins.png" alt="" />
                  </ShareNetwork>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="buy">
          <div class="buy_title">
            {{ list.commodityName }}
          </div>
          <div class="buy_price flex-row">
            <div class="buy_price_title">
              Price:
              <span style="margin-left: calc(10* 100vw / 1920);">
                {{ list.originalUnit }}&nbsp;/&nbsp;{{list.minNumUnit }}
              </span>
            </div>
            <div class="buy_price_price1">
              <!-- ${{
              list.fullDecreaseQuantity && list.fullPriceReduction && num >= list.fullDecreaseQuantity
              ? list.fullPriceReduction
              : list.presentPrice
              }} -->
              ${{ list.presentPrice }}
            </div>
            <!-- <div class="buy_price_price2" v-if="list.originalPrice&&list.originalPrice>0">${{ list.originalPrice }}
            </div>
            <div class="buy_price_price1">{{ list.originalUnit }}</div> -->
            <div class="buy_price_price3" v-if="list.discountsType == 2 && list.gtNumberPrice > 0">${{
              list.gtNumberPrice }}</div>
            <div class="buy_price_price1" v-if="list.discountsType == 2 && list.gtNumberDiscountsPrice > 0">${{
              list.gtNumberDiscountsPrice }}
            </div>
            <div class="buy_price_price1" v-if="list.discountsType == 1 && list.fullPriceReduction > 0">${{
              list.fullPriceReduction }}
            </div>
          </div>
          <div class="buy_totalPrice flex-row">
            <div class="buy_totalPrice_title">MOQ(Min.Order):</div>
            <div class="MOQ">{{ list.minNum }}</div>
            <div class="MOQ2" v-if="list.discountsType == 2 && list.gtNumberMin > 0 && list.gtNumberMax > 0">{{
              list.gtNumberMin
              }}&nbsp;-&nbsp;{{ list.gtNumberMax }}</div>
            <div class="MOQ" v-if="list.discountsType == 2 && list.gtNumberDiscounts > 0">≥{{ list.gtNumberDiscounts }}
            </div>
            <div class="MOQ" v-if="list.discountsType == 1 && list.fullDecreaseQuantity > 0">≥{{
              list.fullDecreaseQuantity }}
            </div>
          </div>
          <div class="buy_quantity flex-row">
            <div class="buy_quantity_title">Quantity:</div>
            <div class="buy_quantity_count flex-row">
              <button @click="numCount(-1)">-</button>
              <!-- <div class="num">{{ num }}</div> -->
              <input class="num" type="number" v-model="num" />
              <button @click="numCount(1)">+</button>
            </div>
            <!-- <div class="buy_quantity_available">(99999 available)</div> -->
            <!-- <div class="MOQ">{{ list.minNumUnit }}</div> -->
          </div>
          <!-- <div class="buy_size flex-row">
            <div class="buy_size_title">Size:</div>
            <div class="buy_sizebox">
              <div class="bug_size_item" :class="sizeActive == index ? 'buy_size_itemActive' : ''"
                v-for="(item, index) in list.specificationsVos" :key="index" @click="changeSizeActive(index)">
                <el-tooltip class="item" effect="dark" :content="item.specifications" placement="top">
                  <div class="guige">
                    {{ item.specifications }}
                  </div>
                </el-tooltip>
                <div class="guigeColor" v-if="item.specificationsImage  && item.specificationsImage != null">
                  <img style="width: 100%;height: 100%;" :src="$baseUrl+item.specificationsImage.split(',')[0]" />
                </div>
                <div v-else class="guigeColor">
                </div>
              </div>
            </div>
          </div> -->
          <div class="buy_size flex-row">
            <div class="parentSku" v-for="(parentItem,parentIndex) in list.skuParentList">
              <div class="buy_size_title">{{parentItem.skuName}}:</div>
              <div class="buy_sizebox">
                <!-- <div class="bug_size_item" v-for="(childItem, childIndex) in parentItem.skuChildList" :key="childIndex"
                  @click="changeSizeActive(parentIndex,childIndex)"
                  :class="childItem.skuStock && childItem.skuStock > 0 ? sizeActive === childIndex ? 'buy_size_itemActive' : '' : 'buy_size_itemDisabled'"> -->
                <div class="bug_size_item" v-for="(childItem, childIndex) in parentItem.skuChildList" :key="childIndex"
                  v-on:click="specificationBtn(childItem.skuValueId,parentIndex,$event,childIndex)"
                  v-bind:class="[childItem.isShow? '' : 'buy_size_itemDisabled',subIndex[parentIndex] == childIndex?'buy_size_itemActive':'']">
                  <div class="guigeColor" v-if="childItem.skuImgUrl  && childItem.skuImgUrl != null">
                    <!-- <img
                      :style="childItem.skuStock && childItem.skuStock > 0 ? 'width: 100%;height: 100%;' : 'width: 100%;height: 100%;opacity: .3;'"
                      :src="$baseUrl+childItem.skuImgUrl.split(',')[0]" /> -->
                    <img
                      :style="childItem.isShow ? 'width: 100%;height: 100%;' : 'width: 100%;height: 100%;opacity: .3;'"
                      :src="$baseUrl+childItem.skuImgUrl.split(',')[0]" />
                  </div>
                  <!-- <div v-else class="guigeColor">
                  </div> -->
                  <div class="guige">
                    {{ childItem.skuValue }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buy_refund1 flex-row">
            <div class="buy_refund_title1">Shipping:</div>
            <div class="buy_shipping1">
              <span v-for="(item, index) in list.modeOfShipment" :key="index">
                {{ item }}
              </span>
            </div>

            <!-- <div class="buy_button_pc flex-row">
              <button class="add" @click="addClick(list.id,list.presentPrice,list.commodityName,list.image,false)">
                Add to Inquiry
              </button>
            </div> -->
            <div class="buy_refund_title1" v-if="list.freightCharge != null && list.freightCharge > 0">
              Freight charge: ${{list.freightCharge}}
            </div>
          </div>
          <!-- <div class="buy_totalPrice flex-row">
            <div class="buy_totalPrice_title">Total Price:</div>
            <div class="totalPrice">${{ totalPriceData }}</div>
            <div class="buy_totalPrice_title" style="margin-left:20px;">Specifications: {{
              list&&list.specifications?list.specifications:'' }}</div>
          </div> -->
          <!-- <div class="buy_totalPrice flex-row" >
            <div class="buy_totalPrice_title">Specifications: {{
              list&&list.specifications?list.specifications:'' }}</div>
            
          </div> -->
          <div class="buy_totalPrice flex-row" style="border-bottom: 1px solid #dddddd;">
            <div class="buy_totalPrice_title">Total Price:</div>
            <div class="totalPrice">${{ totalPriceData }}</div>
          </div>
          <div class="buy_button_pc flex-row">
            <button style="margin-top: calc(20* 100vw / 1920);" class="buyNow" @click="buyfun">Buy Now !</button>
          </div>
          <div class="buy_refund flex-row" v-if="list.isRefund == 1 && isPc == false">
            <div class="buy_refund_title1">Refund:</div>
            <div>
              <div class="buy_refund_title2">
                <!-- Support 7-day return without reason -->
                Active eCommerce Refund Protection
              </div>
              <div class="buy_refund_title3" style="font-weight: bold; color: #000">
                30 Days Cash Back Guarantee
              </div>
            </div>

            <div class="buy_refund_url" @click="privacyClick">View Policy</div>
          </div>
          <!-- <div class="buy_refund flex-row">
            <div class="buy_refund_title1">Shipping:</div>
            <div class="buy_shipping">
              <span v-for="(item, index) in list.modeOfShipment" :key="index">
                {{ item }}
              </span>
            </div>
          </div> -->
          <div class="buy_share flex-row buy_share_phone">
            <div class="buy_share_title">Share:</div>
            <div class="buy_share_loge">
              <!-- <img
                  src="../../../assets/img/Facebook.png"
                  @click="shareFacebook"
                  alt=""
                /> -->
              <ShareNetwork network="facebook" :url="share_url" :title="share_title">
                <img src="../../../assets/img/Facebook.png" alt="" />
              </ShareNetwork>
              <ShareNetwork network="Twitter" :url="share_url" :title="share_title">
                <img src="../../../assets/img/x.png" alt="" />
              </ShareNetwork>
              <!-- <img
                  src="../../../assets/img/x.png"
                  alt=""
                  @click="shareTwitter"
                /> -->
              <!-- <ShareNetwork
                  network="Linkedin"
                  :url="share_url"
                  :title="share_title"
                > -->
              <ShareNetwork network="whatsapp" :url="share_url" :title="share_title">
                <img src="../../../assets/img/whats.png" alt="" />
              </ShareNetwork>
              <!-- <img
                  src="../../../assets/img/whats.png"
                  @click="shareLinkedin"
                  alt=""
                /> -->
              <ShareNetwork network="Pinterest" :url="share_url" :title="share_title" :media="share_img">
                <img src="../../../assets/img/pin.png" alt="" />
              </ShareNetwork>
              <!-- <img
                  src="../../../assets/img/pin.png"
                  @click="sharePinterest"
                  alt=""
                /> -->
              <ShareNetwork network="tiktok" :url="share_url" :title="share_title">
                <img src="../../../assets/img/tik.png" alt="" />
              </ShareNetwork>

              <ShareNetwork network="instagram" :url="share_url" :title="share_title">
                <img src="../../../assets/img/ins.png" alt="" />
              </ShareNetwork>
            </div>
          </div>
          <div class="buy_share flex-row buy_share_phone small_pay">
            <div class="buy_share_title">Payment Method:</div>
            <div class="buy_share_loge">
              <div><img src="@/assets/img/paymethod_1.png" /></div>
              <div><img src="@/assets/img/paymethod_2.png" /></div>
              <div><img src="@/assets/img/paymethod_3.png" /></div>
              <div><img src="@/assets/img/paymethod_4.png" /></div>
              <div><img src="@/assets/img/paymethod_5.jpg" /></div>
              <div><img src="@/assets/img/paymethod_6.jpg" /></div>
              <div><img src="@/assets/img/paymethod_7.jpg" /></div>
            </div>
          </div>
          <div class="buy_button_phone">
            <button class="buyNow" @click="buyfun">Buy Now !</button>
          </div>
          <div class="buy_refunds" v-if="list.isRefund == 1 && isPc == true"">
            <p><img src=" @/assets/img/return.png" />Rerurns & Refunds</p>
          <div>Eliginble for refunds within 30 days of receiving products. <span @click="refundsClick">View
              details</span></div>
        </div>
        <!-- <div class="buy_samples flex-row">
          <div class="buy_samples_title">Samples:</div>
          <div class="buy_samples_detail"><span>US$ {{list&&list.samples?list.samples:1}} </span> Set(Min.Order)
          </div>
        </div> -->
        <!-- <div class="buy_customization flex-row">
          <div class="buy_customization_title">Customization:</div>
          <div class="buy_customization_detail">
            <label>Available</label> -->
        <!-- <span>|</span>
                <img src="@/assets/img/edit.png" class="edit_img" v-if="!isShowEdit" @click="isShowEdit=true" />
                <div class="edit_div" v-else>
                  <input class="num" type="number" v-model="num" />
                </div> -->
        <!-- </div>
        </div> -->


        <div class="buy_nav_phone">
          <div class="buy_share_title">Nav:</div>
          <ul class="flex-row">
            <li @click="businesshomeClick">Home</li>
            <li @click="businessprofileClick">Profile</li>
            <li @click="businessceramicsClick(item.id, false)" v-for="(item, index) in businessNav" :key="index">
              {{ item.categoryName }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="description">
      <div class="description_title">Description</div>
      <div class="description_main" v-html="list.introduce">
      </div>
    </div>

  </div>
  <!-- <div class="frame">
        <div class="frame_box flex-col">
          <h1>Add to Cart</h1>
          <p>This item has been successfully added to the shopping cart !</p>
          <button @click="okButtonClick">OK</button>
          <button @click="viewCartClick">View Cart</button>
        </div>
      </div> -->
  <el-dialog title="Whether to use coupon" :visible.sync="dialogVisible">
    <div class="a-list_coupon" v-if="couponList.length > 0">
      <div class="a-list_coupon_top">
        <div>Coupon</div>
        <div class="a-coupon_top_text">
          You have
          <span>{{ couponList.length }}</span>
          coupons available
        </div>
      </div>
      <div class="a-list_coupon_main">
        <el-radio-group v-model="value">
          <label v-for="(item, index) in couponList" :key="index" @click="changeradio(item.id, item.denomination)">
            <div style="display: flex; align-items: center">
              <div>
                <el-radio :label="item.id" :disabled="totalPrice >= item.fullAmount ? false : true"></el-radio>
              </div>
              <div class="a-list_coupon_coupon">
                <div class="a-coupon_name">{{ item.couponName }}</div>
                <div class="a-coupon_amount">${{ item.denomination }}</div>
                <div class="a-coupon_available" v-if="item.fullAmount && item.fullAmount > 0">
                  If the price exceeds ${{item.fullAmount}}, you can deduct ${{item.denomination}}
                </div>
                <div class="a-coupon_available" v-else>
                  ${{ item.denomination ? item.denomination : 1 }} can be deducted
                </div>
                <div class="a-coupon_vld" v-if="item.endTime != null">
                  Vld: {{ item.endTime }}
                </div>
              </div>
            </div>
          </label>
        </el-radio-group>
      </div>
    </div>
    <div style="text-align: end">
      <el-button @click="nocopBuy">cancel</el-button>
      <el-button type="primary" @click="buyNowfun">confirm</el-button>
    </div>
  </el-dialog>
  <el-dialog title="Seven Day Refund Agreement without Reasons" :visible.sync="policyVisible">
    <div class="policy" v-html="policy"></div>
  </el-dialog>
  <el-dialog title="30 Day Refund Agreement without Reasons" :visible.sync="refundsVisible">
    <div class="policy" v-html="refunds"></div>
  </el-dialog>
  <el-dialog title="Login" :visible.sync="loginVisible" @close="loginClose">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="Login" name="first">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="email" prop="pass">
            <el-input type="email" v-model="ruleForm.email" placeholder="email" style="width: 80%;"></el-input>
          </el-form-item>
          <el-form-item label="password" prop="password">
            <el-input type="password" v-model="ruleForm.password" placeholder="password" style="width: 80%;"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="loginfun(ruleForm)">Login</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="Register" name="second">
        <el-form :model="registerUser" status-icon :rules="registerUserRules" ref="registerUser" label-width="100px"
          class="demo-ruleForm">
          <el-form-item label="email" prop="email">
            <el-input type="email" v-model="registerUser.email" placeholder="email" style="width: 80%;"></el-input>
          </el-form-item>
          <el-form-item label="code" prop="code">
            <el-input type="email" v-model="registerUser.code" placeholder="code" style="width: 80%;"></el-input>
            <el-button @click="sendEmailCode">Verify&nbsp;Mailbox</el-button>
          </el-form-item>
          <el-form-item label="password" prop="password">
            <el-input type="password" v-model="registerUser.password" placeholder="password"
              style="width: 80%;"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="registerEmail">Register</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
  </div>
</template>

<script>
  import {
    shopCategoryListApi,
    businessIntroduction,
    contactUs,
  } from "@/api/business";
  import { loginByEmailApi } from "../../../api/login";
  import { getCookie, setCookie, setUserType } from "../../../utils/auth";
  import businessNav from "@/components/public/index";
  import fineCeramicsSwiper from "./swiper/FineCeramicsSwiper.vue";
  import { sevenAgreement, getDictLabel } from "../../../api/other";
  import { getconsumerCommodityDetail } from "../../../api/index";
  import { getCode, registerEmail } from "@/api/login";
  import {
    shopAddCarApi,
    buyGoods,
    userGetCoupon,
    showPayCarList,
    consumerCouponList,
    buyPromotionGoodsApi,
    getmyInfo
  } from "../../../api/index";

  export default {
    name: "FineCeramics",
    data() {
      return {
        price: 2000, // 单价
        num: 1, // 数量
        totalPrice: 0, // 总价
        value: "",
        list: {},
        oldList: {},
        dialogVisible: false,
        couponList: [], //优惠卷列表
        discountPrice: 0, //优惠金额
        couponUserId: null, //所选优惠卷id
        policy: "",
        policyVisible: false,
        share_id: "",
        share_url: "www.horamite.com",
        share_title: "horamite",
        share_img: "",
        businessNav: [], // 商家导航栏
        customerServiceHotlink: [],
        businessInfo: {},
        isShowEdit: false,
        refundsVisible: false,
        refunds: "",
        sizeActive: 0,
        loginVisible: false,
        ruleForm: {
          email: '',
          password: ''
        },
        rules: {
          email: [
            { required: true, message: 'input email', trigger: 'blur' }
          ],
          password: [
            { required: true, message: 'input password', trigger: 'blur' }
          ]
        },
        userId: '',
        registerUser: {
          email: null,
          code: null,
          password: null
        },
        registerUserRules: {
          email: [
            { required: true, message: 'input email', trigger: 'blur' }
          ],
          code: [
            { required: true, message: 'input code', trigger: 'blur' }
          ],
          password: [
            { required: true, message: 'input password', trigger: 'blur' }
          ]
        },
        isPc: true,
        activeName: "first",


        selectArr: [], //存放被选中的值
        shopItemInfo: {}, //存放要和选中的值进行匹配的数据，map集合 item列表
        subIndex: [], //是否选中 因为不确定是多规格还是但规格，所以这里定义数组来判断

        watchArrChange: null,
      };
    },
    components: {
      fineCeramicsSwiper,
    },
    computed: {
      totalPriceData() {
        var price = 0;
        if (this.list.discountsType == 2) {
          if (this.list.gtNumberMin && this.list.gtNumberMax && this.list.gtNumberPrice && this.num >= this.list.gtNumberMin && this.num <= this.list.gtNumberMax) {
            price = (this.list.gtNumberPrice * this.num);
          }
          if (this.list.gtNumberDiscounts && this.list.gtNumberDiscountsPrice && this.list.gtNumberPrice && this.num >= this.list.gtNumberDiscounts) {
            price = (this.list.gtNumberDiscountsPrice * this.num);
          }
        } else if (this.list.discountsType == 1) {
          if (this.list.fullDecreaseQuantity && this.list.fullPriceReduction && this.num >= this.list.fullDecreaseQuantity) {
            price = (this.list.fullPriceReduction * this.num);
          }
        }

        if (!price || price === 0) {
          //判断运费
          if (this.list.freightCharge != null && this.list.freightCharge > 0) {
            this.totalPrice = (this.list.presentPrice * this.num + this.list.freightCharge).toFixed(2);
          } else {
            this.totalPrice = (this.list.presentPrice * this.num).toFixed(2)
          }
          return this.totalPrice;
        }
        else {
          //判断运费
          if (this.list.freightCharge != null && this.list.freightCharge > 0) {
            this.totalPrice = (price + this.list.freightCharge).toFixed(2);
          } else {
            this.totalPrice = (price).toFixed(2)
          }
          return (this.totalPrice);
        }
      },
    },
    created() {
      this.getParams();
      this.$bus.$on("nav", (res) => {
        if (res) {
          this.businessNav = res;
        }
      });
    },
    activated() {
      // this.getParams();
      // this.shopCategoryList();
      // let smallImgs = document.querySelector(".small-img").children;
      // console.log(smallImgs);
      // smallImgs[0].click();
      // this.clickActive(this.detailsImage[0]);
    },
    watch: {
      $route(to, from) {
        if (document.querySelector(".fineceramics_page")) {
          this.getParams();
        }
        setTimeout(() => {
          let smallImgs = document.querySelector(".small-img").children;
          smallImgs[0].click();
        }, 200);
      },
      'watchArrChange': {
        handler: function (newVal) {
          var clickSku = false;
          if (this.selectArr != null && this.selectArr.length > 0 && this.subIndex != null && this.subIndex.length > 0) {
            if (this.selectArr[0] != null && this.selectArr.length > 0 && this.subIndex[0] != null) {
              var skuImg = this.list?.skuParentList[0]?.skuChildList[this.subIndex[0]]?.skuImgUrl;
              if (skuImg?.length) {
                this.list.detailsImage = skuImg.split(',')
                this.list.image = ''
                this.list.detailsVideo = ''
                clickSku = true;
              }
            }
          }
          if (clickSku === false) {
            this.list.detailsImage = JSON.parse(JSON.stringify(this.oldList.detailsImage))
            this.list.image = JSON.parse(JSON.stringify(this.oldList.image))
            this.list.detailsVideo = JSON.parse(JSON.stringify(this.oldList.detailsVideo))
          }
        },
        // deep: true    //深度监听
      }
    },
    methods: {
      handleClick(index) {
        console.log(index)
      },
      loginfun(form) {
        loginByEmailApi({ ...form }).then((res) => {
          if (res.code == 200) {
            if (res.data.userType == 1) {
              this.$message({ message: "Log in successfully", type: "success" });
              // localStorage.setItem('token',res.data.token)
              setCookie("token", res.data.token);
              setUserType("userType", res.data.userType);
              window.location.reload()
            } else {
              // let businessId = "";
              //商家
              shopMyInfo().then((res) => {
                if (res.code == 200) {
                  var userInfo = res.data;
                  this.businessId = userInfo.businessId;

                  // 缓存商户id
                  sessionStorage.setItem("businessId", this.businessId);
                  this.shopCategoryList();
                  // this.businessIntroduction(userInfo.businessId,userInfo)
                  window.location.reload()
                } else {
                  this.$message({ message: res.msg, type: "error" });
                  return;
                }
              });
              this.$message({ message: "Log in successfully", type: "success" });
              // localStorage.setItem('token',res.data.token)
              setCookie("token", res.data.token);
              setUserType("userType", res.data.userType);
            }
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      },
      getDictLabel() {
        getDictLabel("min_num_unit").then((res) => {
          // console.log(res, "通用字典");
          if (res.code == 200) {
            res.data.forEach((item) => {
              if (item.dictValue == this.list.minNumUnit) {
                this.list.minNumUnit = item.dictLabel;
              }
            });
          }
        });
      },
      contactUs() {
        contactUs(sessionStorage.getItem("businessId")).then((res) => {
          // console.log(res);
          if (res.code == 200) {
            this.customerServiceHotlink = res.data;
          }
        });
      },
      businessIntroductionApi() {
        businessIntroduction(sessionStorage.getItem("businessId")).then((res) => {
          if (res.code == 200) {
            // console.log(res);
            this.businessInfo = res.data;
          } else if (res.code == 401) {
            this.$message({
              message: res.code,
              type: "error",
            });
            //未登录
            this.$router.push({
              path: "/login",
              query: {
                box8: false,
                phoneHomeImg: false,
                bool: false,
              },
            });
            return;
          } else {
            // this.$message({ message: res.msg, type: "error" });
          }
        });
      },
      contactUsClick() {
        // this.$router.push({
        //   path: "/contactus",
        //   query: {
        //     bool: false,
        //     box8: false,
        //     phoneHomeImg: false,
        //     noBusiness: false,
        //   },
        // });
        this.$router.push({
          path: "/businessprofile",
          query: {
            noBusiness: false,
          },
        });
      },
      shareEmail() {
        const subject = this.share_title;
        const body = `http://www.etouch-b2b.com`;
        window.open(
          `mailto:?subject=${subject}&body=` + encodeURIComponent(body)
        );
      },
      shareFacebook() {
        function popupwindow(url, title) {
          return window.open(
            "http://www.facebook.com/sharer.php?u=" + encodeURIComponent(url),
            //  +
            // +"&t=" +
            // encodeURIComponent(title),
            "_blank",
            "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
          );
        }
        popupwindow(this.share_url, this.share_title);
      },
      shareTwitter() {
        function popupwindow(url, title) {
          return window.open(
            "https://twitter.com/intent/tweet?url=" +
            encodeURIComponent(url) +
            "&text=" +
            encodeURIComponent(title),
            "_blank",
            "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
          );
        }
        popupwindow(this.share_url, this.share_title);
      },
      shareLinkedin() {
        function popupwindow(url, title) {
          return window.open(
            "https://www.linkedin.com/shareArticle?mini=true" +
            "&url=" +
            encodeURIComponent(url) +
            "&text=" +
            encodeURIComponent(title),
            "_blank",
            "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
          );
        }
        popupwindow(this.share_url, this.share_title);
      },
      sharePinterest() { },
      shareWhatsApp() {
        function popupwindow(url, title) {
          return window.open(
            "https://api.whatsapp.com/send?url=" +
            encodeURIComponent(url) +
            "&text=" +
            encodeURIComponent(title),
            "_blank",
            "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
          );
        }
        popupwindow(this.share_url, this.share_title);
      },
      homeClick() {
        return
        if (sessionStorage.getItem("businessId")) {
          this.$router.push({
            path: "/businesshome",
            query: {
              noBusiness: false,
              businessId: sessionStorage.getItem("businessId"),
            },
          });
        } else {
          this.$router.push({
            path: "/",
            query: {
              bool: true,
              noBusiness: true,
            },
          });
        }

      },
      // 跳转到privay页面
      privacyClick() {
        sevenAgreement().then((res) => {
          if (res.code == 200) {
            this.policy = res.data.seven_day_return_agreement;
            this.policyVisible = true;
          }
        });
        // this.$router.push({
        //   path: "/privacy",
        //   query: {
        //     bool: false,
        //     box8: false,
        //     phoneHomeImg: false,
        //   },
        // });
      },
      // 30天无理由退货协议
      refundsClick() {
        sevenAgreement().then((res) => {
          if (res.code == 200) {
            this.refunds = res.data.seven_day_return_agreement;
            this.refundsVisible = true;
          }
        });
      },
      // 商家首页
      businesshomeClick() {
        this.$router.push({
          path: "/businesshome",
          query: {
            noBusiness: false,
            businessId: sessionStorage.getItem("businessId"),
          },
        });
      },
      // 跳转到公司介绍
      businessprofileClick() {
        this.$router.push({
          path: "/businessprofile",
          query: {
            noBusiness: false,
          },
        });
      },
      businessceramicsClick(id, isbusinessbottomnav) {
        this.$router.push({
          path: "/businessceramics",
          query: {
            noBusiness: false,
            id,
            isbusinessbottomnav,
          },
        });
      },
      nocopBuy() {
        this.discountPrice = 0;
        this.couponUserId = null;
        this.buyNowfun();
      },
      // async buyNowfun() {
      //   if (this.num < this?.list?.minNum) {
      //     this.$message({
      //       message:
      //         "The purchase quantity cannot be less than the minimum order quantity",
      //       type: "warning",
      //     });
      //   }
      //   let obj = {
      //     specificationsId: 0
      //   }
      //   if (this.sizeActive > 0 && this.list?.specificationsVos?.length) {
      //     obj.specificationsId = this.list.specificationsVos[this.sizeActive].id
      //   }
      //   let data = {
      //     commodityId: this.list.id,
      //     orderType: 1,
      //     ...obj,
      //     quantity: this.num,
      //     couponUserId: this.couponUserId,
      //     discountPrice: this.discountPrice,
      //     totalPrice: this.totalPrice,
      //     payPrice: this.totalPrice - this.discountPrice,
      //     freightCharge: this.list.freightCharge != null ? (this.list.freightCharge).toFixed(2) : null,
      //     userId: this.userId
      //   };
      //   buyPromotionGoodsApi({ ...data }).then((res) => {
      //     if (res.code == 200) {
      //       this.dialogVisible = false;
      //       this.value = "";
      //       this.$router.push({
      //         path: "/pay",
      //         query: {
      //           orderid: res.data.orderNo,
      //           businessId: this.list.businessId,
      //           orderType: 1,
      //           price: data.payPrice,
      //           bool: false,
      //           box8: false,
      //           phoneHomeImg: false,
      //         },
      //       });
      //     } else if (res.code == 401) {
      //       this.$message({
      //         message: "Please operate after login",
      //         type: "error",
      //       });
      //       this.loginVisible = true
      //     } else {
      //       this.$message({ message: res.msg, type: "error" });
      //     }
      //   });
      // },

      async buyNowfun() {
        let obj = {
          specificationItemValue: null
        }
        if (this.selectArr?.length && this.list?.skuItemList?.length) {
          obj.specificationItemValue = this.selectArr.filter(function (item) { return item; }).join(";")
        }
        let data = {
          commodityId: this.list.id,
          orderType: 1,
          ...obj,
          quantity: this.num,
          couponUserId: this.couponUserId,
          discountPrice: this.discountPrice,
          totalPrice: this.totalPrice,
          payPrice: this.totalPrice - this.discountPrice,
          freightCharge: this.list.freightCharge != null ? (this.list.freightCharge).toFixed(2) : null,
          userId: this.userId
        };
        buyPromotionGoodsApi({ ...data }).then((res) => {
          if (res.code == 200) {
            this.dialogVisible = false;
            this.value = "";
            this.$router.push({
              path: "/pay",
              query: {
                orderid: res.data.orderNo,
                businessId: this.list.businessId,
                orderType: 1,
                price: data.payPrice,
                bool: false,
                box8: false,
                phoneHomeImg: false,
              },
            });
          } else if (res.code == 401) {
            this.$message({
              message: "Please operate after login",
              type: "error",
            });
            this.loginVisible = true
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      },
      loginClose() {
        this.ruleForm.email = ''
        this.ruleForm.password = ''
        this.loginVisible = false
      },
      changeradio(id, money) {
        this.couponUserId = id;
        this.discountPrice = money;
      },
      //领取优惠卷
      receive(e) {
        userGetCoupon(e).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "Successfully received", type: "success" });
          } else if (res.code == 401) {
            this.$message({
              message: "Please operate after login",
              type: "error",
            });
            this.$router.push({
              path: "/login",
              query: {
                bool: false,
                box8: false,
                phoneHomeImg: false,
              },
            });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      },
      // buyfun() {
      //   consumerCouponList(this.list.businessId).then((res) => {
      //     if (res.code == 200) {
      //       this.couponList = res.data;
      //       if (this.couponList.length > 0) {
      //         this.dialogVisible = true;
      //       } else {
      //         this.dialogVisible = false;
      //         this.buyNowfun();
      //       }
      //     } else if (res.code == 401) {
      //       this.$message({
      //         message: "Please operate after login",
      //         type: "error",
      //       });
      //       this.loginVisible = true
      //     }
      //   })
      // },
      buyfun() {
        if (this.selectArr?.length !== this.list?.skuParentList?.length) {
          this.$message({
            message:
              "Please select the product information to order",
            type: "warning",
          });
        } else {
          var isNullResult = this.selectArr.every(element => {
            return element != null && element.length > 0
          });
          if (isNullResult === false) {
            this.$message({
              message:
                "Please select the product information to order",
              type: "warning",
            });
          } else {
            var skuItemArray = this.selectArr.filter(function (item) { return item; }).join(";")
            var findArray = this.list.skuItemList.filter(item => {
              return item.skuValueArray == skuItemArray && item.isDel != 1
            })
            if (findArray == null || findArray == undefined || findArray.length <= 0) {
              this.$message({
                message:
                  "Please select the product information to order",
                type: "warning",
              });
            } else {
              var findItem = findArray[0];
              if (this.num < findItem.minNum) {
                this.$message({
                  message:
                    "The purchase quantity cannot be less than the minimum order quantity",
                  type: "warning",
                });
              } else {
                consumerCouponList(this.list.businessId).then((res) => {
                  if (res.code == 200) {
                    this.couponList = res.data;
                    if (this.couponList.length > 0) {
                      this.dialogVisible = true;
                    } else {
                      this.dialogVisible = false;
                      this.buyNowfun();
                    }
                  } else if (res.code == 401) {
                    this.$message({
                      message: "Please operate after login",
                      type: "error",
                    });
                    this.loginVisible = true
                  }
                })
              }
            }

          }

        }
      },
      viewCartClick() {
        document.querySelector(".frame").style.display = "none";
        this.$router.push({
          path: "/cart",
          query: {
            phoneHomeImg: false,
            box8: false,
            bool: false,
          },
        });
      },
      // 点击确定加入购物车按钮  移动
      okButtonClick() {
        document.querySelector(".frame").style.display = "none";
      },

      // 添加到购物车
      // addShop() {
      //   if (this.num < this.list.minNum) {
      //     this.$message({
      //       message:
      //         "The purchase quantity cannot be less than the minimum order quantity",
      //       type: "warning",
      //     });
      //   } else {
      //     let obj = {
      //       specificationsId: 0
      //     }
      //     if (this.sizeActive > 0 && this.list?.specificationsVos?.length) {
      //       obj.specificationsId = this.list.specificationsVos[this.sizeActive].id
      //     }
      //     shopAddCarApi({ commodityId: this.list.id, quantity: this.num, ...obj }).then(
      //       (res) => {
      //         if (res.code == 200) {
      //           this.$message({ message: "Successfully added", type: "success" });
      //         } else if (res.code == 401) {
      //           this.$message({
      //             message: "Please operate after login",
      //             type: "error",
      //           });
      //           this.$router.push({
      //             path: "/login",
      //             query: {
      //               bool: false,
      //               box8: false,
      //               phoneHomeImg: false,
      //             },
      //           });
      //         } else {
      //           this.$message({ message: res.msg, type: "error" });
      //         }
      //       }
      //     );
      //   }
      // },
      addShop() {
        if (this.selectArr?.length !== this.list?.skuParentList?.length) {
          this.$message({
            message:
              "Please select the product information to order",
            type: "warning",
          });
        } else {
          var isNullResult = this.selectArr.every(element => {
            return element != null && element.length > 0
          });
          if (isNullResult === false) {
            this.$message({
              message:
                "Please select the product information to order",
              type: "warning",
            });
          } else {
            var skuItemArray = this.selectArr.filter(function (item) { return item; }).join(";")
            var findArray = this.list.skuItemList.filter(item => {
              return item.skuValueArray == skuItemArray && item.isDel != 1
            })
            if (findArray == null || findArray == undefined || findArray.length <= 0) {
              this.$message({
                message:
                  "Please select the product information to order",
                type: "warning",
              });
            } else {
              var findItem = findArray[0];
              if (this.num < findItem.minNum) {
                this.$message({
                  message:
                    "The purchase quantity cannot be less than the minimum order quantity",
                  type: "warning",
                });
              } else {
                let obj = {
                  specificationItemValue: null
                }
                if (this.selectArr?.length && this.list?.skuItemList?.length) {
                  obj.specificationItemValue = this.selectArr.filter(function (item) { return item; }).join(";")
                }
                shopAddCarApi({ commodityId: this.list.id, quantity: this.num, ...obj }).then(
                  (res) => {
                    if (res.code == 200) {
                      this.$message({ message: "Successfully added", type: "success" });
                    } else if (res.code == 401) {
                      this.$message({
                        message: "Please operate after login",
                        type: "error",
                      });
                      this.$router.push({
                        path: "/login",
                        query: {
                          bool: false,
                          box8: false,
                          phoneHomeImg: false,
                        },
                      });
                    } else {
                      this.$message({ message: res.msg, type: "error" });
                    }
                  }
                );
              }
            }
          }
        }
      },
      getParams() {
        // this.list = JSON.parse(this.$route.query.list);
        let id = this.$route.query.id;
        this.userId = this.$route.query.userId;
        getconsumerCommodityDetail(id).then((res) => {
          if (res.code == 200) {
            if (res?.data?.specificationsVos) {
              var addNowSpecification = {
                id: res.data.id,
                specificationsImage: res.data.image,
                specifications: res.data.specifications,
              }
              res.data.specificationsVos.unshift(addNowSpecification)
              // res.data.specificationsVos.unshift({
              //   specifications: res.data.specifications
              // })
              res.data.specificationsVos.forEach(item => {
                if (item.specifications) {
                  item.specifications = item.specifications.replace(/&/g, ' ')
                }
              })
            }
            this.list = res.data;
            if (this.list.skuItemList) {
              //存储到map集合中
              for (var i in this.list.skuItemList) {
                this.shopItemInfo[this.list.skuItemList[i].skuValueArray] = this.list.skuItemList[i];
              }
              this.checkItem();
            }
            this.oldList = JSON.parse(JSON.stringify(res.data))
            this.share_id = this.list.id;
            this.share_url = document.location.href;
            this.share_title = this.list.commodityName;
            this.share_img = this.$baseUrl + this.list.image;
            if (this.list.modeOfShipment) {
              this.list.modeOfShipment = this.list.modeOfShipment.split(",");
            }
            sessionStorage.setItem("businessId", this.list.businessId);
            this.contactUs();
            this.getDictLabel();
            shopCategoryListApi(this.list.businessId).then((res) => {
              if (res.code == 200) {
                this.$bus.$emit("nav", res.data);
              }
            });
            businessIntroduction(this.list.businessId).then((res) => {
              if (res.code == 200) {
                this.$bus.$emit("image", res.data.image);
              }
            });
          }
        });
      },

      specificationBtn: function (skuValueId, parentIndex, event, childIndex) {
        var isClick = this.list?.skuParentList[parentIndex]?.skuChildList[childIndex]?.isShow;
        if (isClick && isClick == true) {
          if (this.selectArr[parentIndex] != skuValueId) {
            this.selectArr[parentIndex] = skuValueId;
            this.subIndex[parentIndex] = childIndex;
            //赋值给被监听的变量，进行图片更新
            this.watchArrChange = this.subIndex[parentIndex];
          } else {
            this.selectArr[parentIndex] = "";
            this.subIndex[parentIndex] = -1; //去掉选中的颜色 
            //赋值给被监听的变量，进行图片更新
            this.watchArrChange = this.subIndex[parentIndex];
          }
          this.changeSkuList();
          this.checkItem();
          this.$nextTick(() => {
            setTimeout(() => {
              let smallImgs = document.querySelector(".small-img").children;
              smallImgs[0].click();
            }, 200);
          });
        }
      },
      //选中规格后更新详情数据
      changeSkuList() {
        var findSku = false;
        //判断是否选中好所有规格项
        if (this.selectArr?.length === this.list?.skuParentList?.length) {
          var isNullResult = this.selectArr.every(element => {
            return element != null && element.length > 0
          });
          if (isNullResult === true) {
            var skuItemArray = this.selectArr.filter(function (item) { return item; }).join(";")
            var findArray = this.list.skuItemList.filter(item => {
              return item.skuValueArray == skuItemArray && item.isDel != 1
            })
            if (findArray != null && findArray.length > 0 && findArray[0]) {
              findSku = true;
              var findItem = findArray[0];
              this.list.minNum = findItem.minNum;
              this.list.stock = findItem.stock;
              this.list.presentPrice = findItem.presentPrice;
              this.list.originalUnit = findItem.originalUnit;
              this.list.minNumUnit = findItem.minNumUnit;
            }
          }
        }
        if (findSku === false) {
          this.list = JSON.parse(JSON.stringify(this.oldList));
        }
        if (this.list.modeOfShipment != null && this.list.modeOfShipment.length > 0) {
          if (!Array.isArray(this.list.modeOfShipment)) {
            this.list.modeOfShipment = this.list.modeOfShipment?.split(",");
          }

        }
      },
      checkItem: function () {
        var option = this.list.skuParentList;
        var result = [];  //定义数组存储被选中的值
        for (var i in option) {
          result[i] = this.selectArr[i] ? this.selectArr[i] : '';
        }
        for (var i in option) {
          var last = result[i];  //把选中的值存放到字符串last去
          for (var k in option[i].skuChildList) {
            result[i] = option[i].skuChildList[k].skuValueId; //赋值，存在直接覆盖，不存在往里面添加name值
            this.$set(this.list.skuParentList[i].skuChildList[k], "isShow", this.isMay(result));//在数据里面添加字段isShow来判断是否可以选择
            // option[i].skuChildList[k].isShow = this.isMay(result); //在数据里面添加字段isShow来判断是否可以选择
          }
          result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时避免被覆盖

        }
        this.$forceUpdate(); //重绘
      },
      isMay: function (result) {
        for (var i in result) {
          if (result[i] == '') {
            return true; //如果数组里有为空的值，那直接返回true
          }
        }
        return this.shopItemInfo[result.filter(function (item) { return item; }).join(";")]?.stock <= 0 ? false : true; //匹配选中的数据的库存，若不为空返回true反之返回false
      },
      clickActive(e) {
        let smallImgs = document.querySelector(".small-img").children;
        // console.log(smallImgs);
        for (let i = 0; i < smallImgs.length; i++) {
          // 移除类
          if (smallImgs[i].classList.contains("small-img-active")) {
            smallImgs[i].classList.remove("small-img-active");
          }
        }
        let currentTarget = e.currentTarget;
        currentTarget.classList.add("small-img-active");
        let ele = currentTarget.children[0].cloneNode(false); // 复制点击的视频或者图片
        // console.log(currentTarget.parentNode.previousElementSibling);
        let bigImg = currentTarget.parentNode.nextSibling.firstElementChild; //  获取显示大盒子
        // if (bigImg.children[0]) {
        //   bigImg.children[0].remove(); // 移除大盒子的子节点
        // }
        for (let index = 0; index < bigImg.children.length; index++) {
          bigImg.children[index].remove(); // 移除大盒子的子节点
        }
        bigImg.appendChild(ele); // 给大盒子添加复制的节点
      },
      numCount(number) {
        this.num = Number.parseFloat(this.num);
        // console.log(this.num);
        this.num += number;
        if (this.num <= 1) {
          this.num = 1;
        }
      },
      // 跳转到inquiry详情-发询盘页面
      addClick(id, price, name, image, bool) {
        this.$router.push({
          path: "/inquiry",
          query: {
            id,
            price,
            name,
            num: this.num,
            image,
            bool,
            box8: false,
            phoneHomeImg: false,
            noBusiness: false,
          },
        });
        // console.log(totalPrice);
      },
      // pc端跳转到购物车页面
      viewCartClick() {
        this.$router.push({
          path: "/cart",
          query: {
            phoneHomeImg: false,
            box8: false,
            bool: false,
          },
        });
      },
      // 移动端添加购物车
      addCartClick() {
        // document.querySelector(".frame").style.display = "block";
        shopAddCarApi({ commodityId: this.list.id, quantity: this.num }).then(
          (res) => {
            if (res.code == 200) {
              this.$message({ message: "Successfully added", type: "success" });
            } else if (res.code == 401) {
              this.$message({
                message: "Please operate after login",
                type: "error",
              });
              this.$router.push({
                path: "/login",
                query: {
                  bool: false,
                  box8: false,
                  phoneHomeImg: false,
                },
              });
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          }
        );
      },
      // changeSizeActive(index) {
      //   this.sizeActive = index
      //   if (index) {
      //     var { presentPrice, minNum, minNumUnit, originalUnit, specifications, fullDecreaseQuantity, fullPriceReduction, specificationsName, samples, specificationsImage, gtNumberMin, gtNumberMax, gtNumberPrice, gtNumberDiscounts, gtNumberDiscountsPrice, discountsType, freightCharge } = this.list.specificationsVos[index]
      //     var detailsImage = []
      //     if (specificationsImage?.length) {
      //       detailsImage = specificationsImage.split(',')
      //     }
      //     let list = { ...this.list, presentPrice, minNum, minNumUnit, originalUnit, originalPrice: presentPrice, specifications, fullDecreaseQuantity, fullPriceReduction, commodityName: specificationsName, samples, detailsImage, gtNumberMin, gtNumberMax, gtNumberPrice, gtNumberDiscounts, gtNumberDiscountsPrice, discountsType, freightCharge, image: '' }
      //     this.list = JSON.parse(JSON.stringify(list))
      //     console.log(this.list)
      //   } else {
      //     this.list = JSON.parse(JSON.stringify(this.oldList))
      //   }
      //   this.$nextTick(() => {
      //     setTimeout(() => {
      //       let smallImgs = document.querySelector(".small-img").children;
      //       smallImgs[0].click();
      //     }, 200);
      //   })
      // },
      changeSizeActive(parentIndex, childIndex) {
        if (this.list?.skuParentList[parentIndex]?.skuChildList[childIndex]?.skuStock <= 0) {
          return;
        } else {
          if (this.sizeActive === childIndex) {
            this.sizeActive = -1;
            this.list.detailsImage = JSON.parse(JSON.stringify(this.oldList.detailsImage))
            this.list.image = JSON.parse(JSON.stringify(this.oldList.image))
            this.list.detailsVideo = JSON.parse(JSON.stringify(this.oldList.detailsVideo))
          } else if (parentIndex != null && childIndex != null) {
            this.sizeActive = childIndex
            //更新商品信息为规格信息
            if (parentIndex != null && childIndex != null) {
              var skuImg = this.list?.skuParentList[parentIndex]?.skuChildList[childIndex]?.skuImgUrl;
              if (skuImg?.length) {
                this.list.detailsImage = skuImg.split(',')
                this.list.image = null
                this.list.detailsVideo = null
              }
            }
          }
          this.$nextTick(() => {
            setTimeout(() => {
              let smallImgs = document.querySelector(".small-img").children;
              smallImgs[0].click();
            }, 200);
          })
        }
      },
      sendEmailCode() {
        getCode(this.registerUser.email).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "The verification code was sent successfully.",
              type: "success",
            });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      },
      registerEmail() {
        registerEmail(this.registerUser).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "Registered successfully",
              type: "success",
            });
            var { email, password } = this.registerUser
            this.loginfun({
              email,
              password
            })
            this.registerUser = {
              email: null,
              password: null,
              code: null,
            };
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        });
      },
    },
    mounted() {
      this.getDictLabel();
      this.contactUs();
      // this.businessIntroductionApi();
      setTimeout(() => {
        let smallImgs = document.querySelector(".small-img").children;
        smallImgs[0].click();
      }, 200);
      let bottomnav = document.querySelector(".bottomnav");
      for (let i = 0; i < bottomnav.children.length; i++) {
        bottomnav.children[i].style.color = "#aaa";
      }
      if (document.documentElement.clientWidth > 1080) {
        this.isPc = true;
      } else {
        this.isPc = false;
      }
    },
    // activated() {
    //   console.log("fineceramics activated");
    // },
  };
</script>

<style scoped>
  .contactus_main {
    background-color: #fff;
    padding-bottom: calc(20 * 100vw / 1920);
    border-top: 1px solid #ddd;
  }

  .contactus_title {
    margin-left: calc(20 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .list {
    display: flex;
    font-size: calc(22 * 100vw / 1920);
    padding-top: calc(30 * 100vw / 1920);
    flex-wrap: wrap;
    /* justify-content: space-around; */
  }

  .list_li {
    width: 33.33%;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(30 * 100vw / 1920);
  }

  .list_li>img {
    max-width: 40%;
    max-height: 40%;
    margin-bottom: calc(20 * 100vw / 1920);
  }

  .list_li>div {
    text-align: center;
    margin-bottom: calc(10 * 100vw / 1920);
  }

  .list_li .name {
    font-weight: 600;
  }

  .list_li .teltext {
    align-items: center;
  }

  .list_li .teltext>div {
    font-size: calc(22 * 100vw / 1920);
    margin: 0 calc(10 * 100vw / 1920);
    color: rgb(95, 94, 94);
  }

  .contact_info_img {
    height: calc(620 * 100vw / 1920);
    border-top: 1px solid #ddd;
    margin: 0 calc(20 * 100vw / 1920);
    margin-top: calc(20 * 100vw / 1920);
  }

  .buy_nav_phone {
    display: none;
  }

  ::v-deep .el-radio__label {
    display: none;
  }

  .a-list_coupon {
    margin-top: calc(40 * 100vw / 1920);
  }

  .a-list_coupon_top {
    width: 100%;
    height: calc(73 * 100vw / 1920);
    padding: 0 calc(23 * 100vw / 1920);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: calc(22 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #333333;
    border-bottom: 1px solid #ddd;
  }

  .a-coupon_top_text {
    font-size: calc(16 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }

  .a-coupon_top_text span {
    color: #ff0000;
    margin: 0 calc(5 * 100vw / 1920);
  }

  .a-list_coupon_main {
    padding: calc(14 * 100vw / 1920) calc(18 * 100vw / 1920);
  }

  .a-list_coupon_coupon {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    width: 80%;
    height: calc(120 * 100vw / 1920);
    padding: calc(25 * 100vw / 1920) calc(40 * 100vw / 1920) calc(20 * 100vw / 1920) calc(40 * 100vw / 1920);
    margin-left: 20px;
    /* background: #f0fffb; */
    /* border: calc(2 * 100vw / 1920) solid #13af87; */
    border: calc(2 * 100vw / 1920) solid #fff;
    box-shadow: 0px 0px 13px 0px rgba(102, 102, 102, 0.38);
    margin-bottom: calc(10 * 100vw / 1920);
  }

  .a-coupon_name {
    width: calc(900 * 100vw / 1920);
    word-wrap: break-word;
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #333333;
  }

  .a-coupon_amount {
    font-size: calc(22 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #ff0000;
  }

  .a-coupon_available,
  .a-coupon_vld {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
  }

  .fineceramics_page {
    width: 100%;
    background-color: #f1f1f1;
  }

  .fineceramics {
    /*width: calc(1200 * 100vw / 1920);*/
    /* width: 100%;
    margin: calc(20 * 100vw / 1920) auto; */

    width: 85%;
    margin: 0 auto;
    background-color: #f1f1f1;
  }

  .fineceramics .directory {
    background-color: #f1f1f1;
    margin: 0;
    padding-bottom: 1.2vw;
    font-size: 0.93vw;
    font-family: Roboto;
    font-weight: 400;
    color: #999999;
  }

  .fineceramics .fineceramics_nav {
    display: none;
  }

  .fineceramics .fine {
    width: 100%;
    padding-left: calc(22 * 100vw / 1920);
    padding-top: calc(25 * 100vw / 1920);
    padding-right: calc(33 * 100vw / 1920);
    padding-bottom: calc(50 * 100vw / 1920);
    background-color: #fff;
  }

  .fineceramics .fine .loupe {
    margin-right: calc(36 * 100vw / 1920);
    /* height: calc(534 * 100vw / 1920);
    overflow: hidden; */
  }

  .fineceramics .fine .loupe .big-img {
    width: calc(677 * 100vw / 1920);
    height: calc(677 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    /* background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: calc(222 * 100vw / 1920) calc(316 * 100vw / 1920);
    background-image: url(../../../assets/img/图层2.png); */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fineceramics .fine .loupe .big-img img {
    max-width: 90%;
    max-height: 90%;
  }

  .fineceramics .fine .loupe .big-img video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .fineceramics .fine .loupe .imageDiv {
    display: flex;
  }

  .fineceramics .fine .loupe .small-img {
    overflow: auto;
    overflow-x: scroll;
    width: calc(167 * 100vw / 1920);
    height: calc(86 * 1000vw / 1920);
    background: #f5f5f5;
    margin-top: calc(4 * 100vw / 1920);
    /* padding-bottom: calc(20 * 100vw / 1920); */
    /* justify-content: space-between; */
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    margin-right: calc(10* 100vw / 1920);
  }

  .small-img-active {
    box-shadow: 0px 0px 13px 0px rgba(102, 102, 102, 0.38);
  }

  .fineceramics .fine .loupe .small-img div {
    width: calc(167 * 100vw / 1920);
    height: calc(161 * 100vw / 1920);
    background: #ffffff;
    border: 1px solid #dddddd;
    margin-right: calc(5 * 100vw / 1920);
    /* background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: calc(52 * 100vw / 1920) calc(74 * 100vw / 1920); */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fineceramics .fine .loupe .small-img div img {
    max-width: 90%;
    max-height: 90%;
  }

  .fineceramics .fine .loupe .small-img div video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .small_pay_phone {
    display: none;
  }

  .small_pay>p {
    font-size: 1.28vw;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .small_pay>div {
    display: flex;
    align-items: center;
    font-size: 1.08vw;
  }

  .small_pay>div>img {
    /* max-width: calc(72 * 100vw / 1920); */
    width: calc(41 * 100vw / 1920);
    height: calc(34 * 100vw / 1920);
    margin-right: 2px;
  }

  .fineceramics .fine .loupe .buy_share {
    margin-top: calc(30 * 100vw / 1920);
  }

  .fineceramics .fine .loupe .buy_share .buy_share_title {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
  }

  .fineceramics .fine .loupe .buy_share .buy_share_loge {
    margin-left: calc(20 * 100vw / 1920);
    display: flex;
  }

  .fineceramics .fine .loupe .buy_share .buy_share_loge img {
    width: calc(45 * 100vw / 1920);
    height: calc(45 * 100vw / 1920);
    margin-right: calc(18 * 100vw / 1920);
  }

  .fineceramics .fine .loupe .add {
    width: calc(170 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    background: #d0fff3;
    border-radius: calc(2 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: bold;
    color: #13af87;
    border: 1px solid #13af87;
    margin-top: calc(30 * 100vw / 1920);
  }

  .fineceramics .fine .loupe .add:hover {
    background: #13af87;
    color: #ffffff;
  }

  /* .fineceramics .fine .loupe .small-img .front {
    background-image: url(../../../assets/img/图层2.png);
  }
  .fineceramics .fine .loupe .small-img .behind {
    background-image: url(../../../assets/img/图层3.png);
  }
  .fineceramics .fine .loupe .small-img .left {
    background-image: url(../../../assets/img/图层2.png);
  }
  .fineceramics .fine .loupe .small-img .right {
    background-image: url(../../../assets/img/图层2.png);
  } */
  .buy {
    /*width: calc(650 * 100vw / 1920);*/
    width: calc(75% - calc(36 * 100vw / 1920) - calc(460 * 100vw / 1920));
  }

  .buy .buy_title {
    word-wrap: break-word;
    font-size: calc(24 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #333333;
    line-height: calc(30 * 100vw / 1920);
    margin-bottom: calc(39 * 100vw / 1920);
  }

  .buy .buy_price {
    height: calc(100 * 100vw / 1920);
    border-top: 1px solid #ddd;
    padding-bottom: calc(40 * 100vw / 1920);
    align-items: flex-end;
  }

  .buy .buy_price .buy_price_title {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
  }

  .buy .buy_price .buy_price_price1 {
    margin-left: calc(15* 100vw / 1920);
    font-size: calc(20 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #666666;
    line-height: calc(35 * 100vw / 1920);
    display: inline-block;
    width: 25%;
    max-width: 25%;
    text-align: center;
  }

  .buy .buy_price .buy_price_price2 {
    margin-left: calc(49 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    text-decoration: line-through;
    color: #aaaaaa;
  }

  .buy .buy_price .buy_price_price3 {
    margin-left: calc(15* 100vw / 1920);
    font-size: calc(20 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #666666;
    line-height: calc(35 * 100vw / 1920);
    display: inline-block;
    width: 25%;
    max-width: 25%;
    text-align: center;
  }

  .buy .buy_quantity {
    height: calc(100 * 100vw / 1920);
    padding-bottom: calc(33 * 100vw / 1920);
    align-items: flex-end;
    border-top: 1px solid #ddd;
  }

  .buy .buy_quantity .buy_quantity_title {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
    line-height: calc(36 * 100vw / 1920);
  }

  .buy .buy_quantity .buy_quantity_count {
    margin-left: calc(61 * 100vw / 1920);
  }

  .buy .buy_quantity .buy_quantity_count button {
    width: calc(36 * 100vw / 1920);
    height: calc(36 * 100vw / 1920);
    background: #defdf5;
    border-radius: 50%;
    font-size: calc(28 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #aaaaaa;
    line-height: calc(28 * 100vw / 1920);
  }

  .buy .buy_quantity .buy_quantity_count button:hover {
    background: #13af87;
    color: #ffffff;
  }

  .buy .buy_quantity .buy_quantity_count .num {
    width: calc(125 * 100vw / 1920);
    height: calc(36 * 100vw / 1920);
    margin: 0 calc(15 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: calc(36 * 100vw / 1920);
  }

  .buy .buy_quantity .buy_quantity_available {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #aaaaaa;
    margin-left: calc(29 * 100vw / 1920);
    line-height: calc(36 * 100vw / 1920);
  }

  .buy .buy_size {
    margin-top: 10px;
    border-top: 1px solid #ddd;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
  }

  .buy .buy_size>.parentSku {
    width: 100%;
    display: flex;
    margin: 10px 0;
  }

  .buy .buy_sizebox {
    display: flex;
    flex-wrap: wrap;
    width: 78%;
  }

  .buy .buy_size .bug_size_item {
    height: calc(40 * 100vw / 1920);
    line-height: calc(40 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    margin-bottom: calc(10 * 100vw / 1920);
    display: flex;
    justify-content: space-around;
    margin-right: calc(10* 100vw / 1920);
    border: 1px solid #13af87;

    .guige {
      display: inline-block;
      padding: 0 calc(10 * 100vw / 1920);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .guigeColor {
      display: inline-block;
      width: calc(40* 100vw / 1920);
    }
  }

  .buy .buy_size .buy_size_itemActive {
    border: 1px solid rgb(250, 1, 1);
  }

  .buy .buy_size .buy_size_itemDisabled {
    cursor: not-allowed;
    color: #b9b0b0;
    border-color: #dadde0;

    /* 通过样式组织点击事件 */
    /* pointer-events: none; */
  }


  .buy .buy_size .buy_size_title {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
    line-height: calc(36 * 100vw / 1920);
    margin-right: calc(30 * 100vw / 1920);
  }

  /*  */
  .coupon {
    width: calc(570 * 100vw / 1920);
    margin-bottom: calc(50 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: rgb(250, 1, 1);
    overflow: hidden;
    /* margin-top: calc(50 * 100vw / 1920); */
  }

  .buy_coupon_title {
    font-size: calc(18 * 100vw / 1920);
    /* padding-top: calc(5 * 100vw / 1920); */
    color: #666666;
  }

  .buy_coupon_main {
    margin-left: calc(60 * 100vw / 1920);
    max-height: calc(150 * 100vw / 1920);
    padding-right: calc(20 * 100vw / 1920);
    /* padding-top: calc(10 * 100vw / 1920); */
    overflow: hidden;
    overflow-y: scroll;
    /* background-color: rgb(247, 243, 186); */
  }

  .coupon_list {
    display: flex;
    align-items: center;
    height: calc(25 * 100vw / 1920);
    padding: 0 calc(10 * 100vw / 1920);
    /* border: 1px solid orange; */
    border: 1px solid #13af87;
    position: relative;
    /* background-color: rgb(247, 196, 101); */
    background: #d0fff3;
    font-size: calc(12 * 100vw / 1920);
    margin-bottom: calc(10 * 100vw / 1920);
  }

  /* .coupon_list::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(-5 * 100vw / 1920);
    width: calc(10 * 100vw / 1920);
    height: 100%;
    background: radial-gradient(
      circle,
      #fff,
      #fff calc(4 * 100vw / 1920),
      transparent calc(5 * 100vw / 1920)
    );
    background-size: calc(10 * 100vw / 1920) calc(15 * 100vw / 1920);
  } */
  .coupon_list .coupon_amount,
  .coupon_conditions {
    margin: 0 calc(5 * 100vw / 1920);
  }

  .coupon_list button {
    height: 100%;
    margin-left: calc(10 * 100vw / 1920);
    padding-left: calc(10 * 100vw / 1920);
    display: flex;
    align-items: center;
    font-size: calc(12 * 100vw / 1920);
    color: rgb(241, 12, 142);
    border-left: 1px dashed rgb(241, 12, 142);
  }

  /*  */
  .buy .buy_totalPrice {
    padding: calc(20* 100vw / 1920) 0;
    align-items: flex-end;
    border-top: 1px solid #ddd;
  }

  .MOQ {
    margin-left: calc(15 * 100vw / 1920);
    font-size: calc(20 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #666666;
    display: inline-block;
    width: 25%;
    max-width: 25%;
    text-align: center;
  }

  .MOQ2 {
    margin-left: calc(15 * 100vw / 1920);
    font-size: calc(20 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #666666;
    display: inline-block;
    width: 25%;
    max-width: 25%;
    text-align: center;
  }

  .buy .buy_totalPrice .buy_totalPrice_title {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
    /* line-height: calc(18 * 100vw / 1920); */
  }

  .buy .buy_totalPrice .totalPrice {
    margin-left: calc(41 * 100vw / 1920);
    font-size: calc(30 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #666666;
  }

  .buy .buyNow,
  .buy .addcart {
    margin-right: calc(20 * 100vw / 1920);
  }

  .buy .buyNow,
  .buy .add,
  .buy .addcart {
    width: calc(170 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    background: #d0fff3;
    border-radius: calc(2 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: bold;
    color: #13af87;
    border: 1px solid #13af87;
  }

  .buy .buyNow:hover,
  .buy .add:hover,
  .buy .addcart:hover {
    background: #13af87;
    color: #ffffff;
  }

  .buy .viewcart {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    text-decoration: underline;
    color: #13af87;
    margin-top: calc(12 * 100vw / 1920);
    margin-left: calc(46 * 100vw / 1920);
    cursor: pointer;
  }

  .buy .buy_refund {
    margin-top: calc(52 * 100vw / 1920);
  }

  .buy .buy_refund1 {
    padding: calc(20* 100vw / 1920) 0;
    border-top: 1px solid #ddd
  }

  .buy .buy_refund .buy_refund_title1 {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
  }

  .buy .buy_refund1 .buy_refund_title1 {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
  }

  .buy .buy_refund .buy_refund_title2,
  .buy .buy_refund1 .buy_refund_title2 {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
    margin-left: calc(71 * 100vw / 1920);
    /* display: block; */
  }

  .buy_refund_title3 {
    font-weight: bold;
    font-size: calc(22 * 100vw / 1920);
    color: #000;
    margin-left: calc(71 * 100vw / 1920);
  }

  .buy_refund_url {
    /* display: inline-block; */
    margin-left: calc(44 * 100vw / 1920);
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    text-decoration: underline;
    color: #13af87;
    line-height: calc(18 * 100vw / 1920);
    /* border-bottom: 1px solid #13af87; */
  }

  .buy .buy_share_phone {
    display: none;
  }

  .buy .buy_share {
    margin-top: calc(64 * 100vw / 1920);
  }

  .buy .buy_share .buy_share_title {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
  }

  .buy .buy_share .buy_share_loge {
    margin-left: calc(82 * 100vw / 1920);
    display: flex;
  }

  .buy .buy_share .buy_share_loge img {
    width: calc(42 * 100vw / 1920);
    height: calc(42 * 100vw / 1920);
    margin-right: calc(18 * 100vw / 1920);
  }

  /* .buy .buy_share .buy_share_loge img:first-child {
    width: calc(11 * 100vw / 1920);
    margin-right: calc(23 * 100vw / 1920);
  } */
  .buy .buy_refunds {
    margin-top: calc(64 * 100vw / 1920);
  }

  .buy .buy_refunds>p {
    font-weight: bold;
    font-size: calc(22 * 100vw / 1920);
    color: #000;
    display: flex;
  }

  .buy .buy_refunds>p>img {
    margin-right: calc(3 * 100vw / 1920);
  }

  .buy .buy_refunds>div {
    color: #333;
    font-size: calc(22 * 100vw / 1920);
    margin-top: calc(20 * 100vw / 1920);
    margin-left: calc(55 * 100vw / 1920);
  }

  .buy .buy_refunds>div>span {
    text-decoration: underline;
  }

  .buy .buy_samples {
    margin-top: calc(64 * 100vw / 1920);
  }

  .buy .buy_samples .buy_samples_title {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
  }

  .buy .buy_samples .buy_samples_detail {
    width: calc(500 * 100vw / 1920);
    margin-left: calc(50 * 100vw / 1920);
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    font-size: calc(24 * 100vw / 1920);
    color: #666;
  }

  .buy .buy_samples .buy_samples_detail span {
    font-size: calc(30 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 500;
    color: #ff0000;
  }

  .buy .buy_customization {
    margin-top: calc(64 * 100vw / 1920);
    align-items: center;
  }

  .buy .buy_customization .buy_customization_title {
    font-size: calc(18 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
  }

  .buy .buy_customization .buy_customization_detail {
    /* margin-left: calc(82 * 100vw / 1920);
    display: flex; */
    width: calc(500 * 100vw / 1920);
    margin-left: calc(50 * 100vw / 1920);
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    /* flex-wrap: wrap; */
    font-size: calc(24 * 100vw / 1920);
    color: #666;
  }

  .buy_customization_detail>span {
    margin: 0 calc(20 * 100vw / 1920);
  }

  .buy_customization_detail .edit_img {
    width: 16px;
    height: 16px;
  }

  .buy_customization_detail .edit_div {
    display: flex;
    align-items: center;
  }

  .buy_customization_detail .edit_div>input {
    width: calc(170 * 100vw / 1920);
    height: calc(50 * 100vw / 1920);
    border: 1px solid #ddd;
    margin-right: calc(20 * 100vw / 1920);
  }

  .contactus_main {
    background-color: #fff;
    padding-bottom: calc(20 * 100vw / 1920);
    border-top: 1px solid #ddd;
  }

  .description {
    width: 100%;
    padding-bottom: calc(150 * 100vw / 1920);
    background-color: #fff;
  }

  .description .description_title {
    margin-left: calc(20 * 100vw / 1920);
    width: calc(140 * 100vw / 1920);
    height: calc(75 * 100vw / 1920);
    white-space: nowrap;
    line-height: calc(75 * 100vw / 1920);
    border-bottom: 5px solid rgba(19, 175, 135, 1);
    font-weight: bolder;
    font-size: 1.28vw;
  }

  .description .description_main {
    border-top: 1px solid #ddd;
    padding: calc(38 * 100vw / 1920) 20px;
    font-size: calc(16 * 100vw / 1920);
    font-family: Roboto;
    font-weight: 400;
    color: #666666;
    line-height: calc(24 * 100vw / 1920);
    word-wrap: break-word;
  }

  .buy_button_phone {
    display: none;
  }

  .buy_shipping {
    width: calc(500 * 100vw / 1920);
    margin-left: calc(50 * 100vw / 1920);
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    font-size: calc(24 * 100vw / 1920);
  }

  .buy_shipping1 {
    width: calc(310 * 100vw / 1920);
    margin-left: calc(50 * 100vw / 1920);
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    font-size: calc(22 * 100vw / 1920);
    /* line-height: 50px; */
  }

  .buy_shipping span {
    display: inline-block;
    width: 50%;
    margin-bottom: calc(15 * 100vw / 1920);
    color: #666;
  }

  .buy_shipping1 span {
    display: inline-block;
    width: 50%;
    color: #666;
  }


  @media screen and (max-width: 1080px) {
    .contactus_title {
      margin-left: calc(40 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(145 * 100vw / 1080);
      white-space: nowrap;
      line-height: calc(145 * 100vw / 1080);
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: calc(48 * 100vw / 1080);
    }

    .list {
      font-size: calc(30 * 100vw / 1080);
      padding-top: calc(30 * 100vw / 1080);
    }

    .list_li {
      width: 50%;
      margin-bottom: calc(30 * 100vw / 1080);
    }

    .list_li .teltext>div {
      font-size: calc(30 * 100vw / 1080);
      margin: 0 calc(10 * 100vw / 1080);
      color: rgb(95, 94, 94);
    }

    .list_li>img {
      max-width: 40%;
      max-height: 40%;
      margin-bottom: calc(20 * 100vw / 1080);
    }

    .list_li>div {
      text-align: center;
      margin-bottom: calc(10 * 100vw / 1080);
    }

    .contact_info_img {
      height: calc(640 * 100vw / 1080);
      border-top: 1px solid #ddd;
      margin: 0 calc(20 * 100vw / 1080);
      margin-top: calc(10 * 100vw / 1080);
    }

    .topnav {
      width: 90%;
      margin: 0 auto;
      overflow: hidden;
      overflow-x: scroll;
    }

    .topnav ul {
      margin-left: 0 !important;
      justify-content: center;
      flex-wrap: nowrap !important;
    }

    .a-list_coupon {
      margin-top: calc(70 * 100vw / 1080);
    }

    .a-list_coupon_top {
      width: 100%;
      height: calc(86 * 100vw / 1080);
      padding: 0 calc(26 * 100vw / 1080) 0 calc(40 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      border-bottom: 1px solid #ddd;
    }

    .a-coupon_top_text {
      font-size: calc(30 * 100vw / 1080);
    }

    .a-coupon_top_text span {
      color: #ff0000;
      margin: 0 calc(5 * 100vw / 1080);
    }

    .a-list_coupon_main {
      padding: calc(48 * 100vw / 1080) calc(18 * 100vw / 1080);
    }

    .a-list_coupon_coupon {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;
      width: 70%;
      height: calc(180 * 100vw / 1080);
      padding: calc(30 * 100vw / 1080) calc(30 * 100vw / 1080) calc(30 * 100vw / 1080) calc(40 * 100vw / 1080);
      /* background: #f0fffb; */
      /* border: calc(2 * 100vw / 1920) solid #13af87; */
      border: calc(2 * 100vw / 1080) solid #fff;
      box-shadow: 0px 0px calc(13 * 100vw / 1080) 0px rgba(102, 102, 102, 0.38);
      margin-bottom: calc(30 * 100vw / 1080);
    }

    .a-coupon_name {
      width: calc(600 * 100vw / 1080);
      word-wrap: break-word;
      font-size: calc(36 * 100vw / 1080);
    }

    .a-coupon_amount {
      font-size: calc(36 * 100vw / 1080);
    }

    .a-coupon_available,
    .a-coupon_vld {
      font-size: calc(30 * 100vw / 1080);
    }

    ::v-deep .el-dialog {
      width: 70%;
    }

    .fineceramics_page {
      width: 100vw;
      background-color: #fff;
    }

    .fineceramics {
      width: 100%;
      margin: 0;
    }

    .fineceramics .directory,
    .fineceramics .fine .loupe {
      display: none;
    }

    .fineceramics .fineceramics_nav {
      display: block;
      width: 100%;
      height: calc(1010 * 100vw / 1080);
      background-color: #f1f1f1;
    }

    .fineceramics .fine {
      width: 100%;
      padding-left: calc(40 * 100vw / 1080);
      padding-top: calc(70 * 100vw / 1080);
      padding-right: calc(40 * 100vw / 1080);
      padding-bottom: 0;
      background-color: #fff;
    }

    .buy {
      width: 100%;
    }

    .buy .buy_title {
      word-wrap: break-word;
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #333333;
      line-height: calc(48 * 100vw / 1080);
      margin-bottom: calc(41 * 100vw / 1080);
    }

    .buy .buy_price {
      height: calc(147 * 100vw / 1080);
      border-top: 1px solid #ddd;
      padding-bottom: calc(31 * 100vw / 1080);
      align-items: flex-end;
    }

    .buy .buy_price .buy_price_title {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #666666;
    }

    .buy .buy_price .buy_price_price1 {
      margin-left: calc(15 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #666666;
      line-height: calc(45 * 100vw / 1080);
      display: inline-block;
      width: calc(40* 1000vw / 1920);
      max-width: calc(40* 1000vw / 1920);
      text-align: center;
    }

    .buy .buy_price .buy_price_price2 {
      font-size: calc(30 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 300;
      text-decoration: line-through;
      color: #aaaaaa;
    }

    .buy .buy_price .buy_price_price3 {
      /* margin-left: calc(15 * 100vw / 1080); */
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #666666;
      line-height: calc(45 * 100vw / 1080);
      display: inline-block;
      width: calc(40* 1000vw / 1920);
      max-width: calc(40* 1000vw / 1920);
      text-align: center;
    }

    .buy .buy_quantity {
      height: calc(129 * 100vw / 1080);
      padding-bottom: calc(18 * 100vw / 1080);
      align-items: flex-end;
      border-top: 1px solid #ddd;
    }

    .buy .buy_quantity .buy_quantity_title {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #666666;
      line-height: calc(60 * 100vw / 1080);
    }

    .buy .buy_quantity .buy_quantity_count {
      margin-left: calc(160 * 100vw / 1080);
    }

    .buy .buy_quantity .buy_quantity_count button {
      width: calc(60 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      background: #defdf5;
      border-radius: 50%;
      font-size: calc(56 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #aaaaaa;
      line-height: calc(45 * 100vw / 1080);
    }

    .buy .buy_quantity .buy_quantity_count .num {
      width: calc(160 * 100vw / 1080);
      height: calc(60 * 100vw / 1080);
      margin: 0 calc(40 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #333333;
      text-align: center;
      line-height: calc(60 * 100vw / 1080);
    }

    .buy .buy_quantity .buy_quantity_available {
      font-size: calc(30 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #aaaaaa;
      margin-left: calc(50 * 100vw / 1080);
      line-height: calc(60 * 100vw / 1080);
    }

    .buy .buy_size {
      margin-top: 10px;
      padding: calc(30 * 100vw / 1080) 0;
      border-top: 1px solid #ddd;
    }

    .buy .buy_sizebox {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .buy .buy_size .bug_size_item {
      height: calc(160 * 100vw / 1920);
      line-height: calc(160 * 100vw / 1920);
      font-size: calc(60 * 100vw / 1920);
      padding-left: calc(20 * 100vw / 1920);
      margin-bottom: calc(10 * 100vw / 1920);
      width: 100%;
      display: flex;
      justify-content: space-around;
    }

    .buy .buy_size .bug_size_item>.guige {
      display: inline-block;
      width: 75%;
      border: calc(1 * 100vw / 1920) solid #13af87;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .buy .buy_size .bug_size_item>.guigeColor {
      display: inline-block;
      width: 12%;
      border: calc(1 * 100vw / 1920) solid #13af87;
      background-color: #13af87;
    }

    .buy .buy_size .buy_size_itemActive>.guige {
      display: inline-block;
      width: 75%;
      border: calc(1 * 100vw / 1920) solid rgb(250, 1, 1);
      color: rgb(250, 1, 1);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .buy .buy_size .buy_size_itemActive>.guigeColor {
      display: inline-block;
      width: 12%;
      border: calc(1 * 100vw / 1920) solid rgb(250, 1, 1);
      color: rgb(250, 1, 1);
      background-color: rgb(250, 1, 1);
    }

    .buy .buy_size .buy_size_title {
      font-size: calc(60 * 100vw / 1920);
      font-family: Roboto;
      font-weight: 400;
      color: #666666;
      line-height: calc(60 * 100vw / 1920);
      margin-right: calc(30 * 100vw / 1920);
    }

    /*  */
    .coupon {
      /* width: calc(860 * 100vw / 1080); */
      width: 100%;
      margin-bottom: calc(30 * 100vw / 1080);
      margin-top: calc(30 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: rgb(250, 1, 1);
      overflow: hidden;
    }

    .buy_coupon_title {
      font-size: calc(36 * 100vw / 1080);
      /* padding-top: calc(5 * 100vw / 1920); */
      color: #666666;
      /* margin-top: calc(40 * 100vw / 1080); */
    }

    .buy_coupon_main {
      margin-left: calc(165 * 100vw / 1080);
      max-height: calc(150 * 100vw / 1080);
      padding-right: calc(20 * 100vw / 1080);
      /* padding-top: calc(10 * 100vw / 1920); */
      overflow: hidden;
      overflow-y: scroll;
      /* background-color: rgb(247, 243, 186); */
    }

    .coupon_list {
      display: flex;
      align-items: center;
      height: calc(55 * 100vw / 1080);
      padding: 0 calc(10 * 100vw / 1080);
      background: #d0fff3;
      border: 1px solid #13af87;
      /* border: 1px solid orange; */
      position: relative;
      /* background-color: rgb(247, 196, 101); */
      font-size: calc(26 * 100vw / 1080);
      margin-bottom: calc(10 * 100vw / 1080);
    }

    /* .coupon_list::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(-10 * 100vw / 1080);
      width: calc(20 * 100vw / 1080);
      height: 100%;
      background: radial-gradient(
        circle,
        #fff,
        #fff calc(8 * 100vw / 1080),
        transparent calc(10 * 100vw / 1080)
      );
      background-size: calc(20 * 100vw / 1080) calc(25 * 100vw / 1080);
    } */
    .coupon_list .coupon_amount,
    .coupon_conditions {
      margin: 0 calc(5 * 100vw / 1920);
    }

    .coupon_list button {
      height: 100%;
      margin-left: calc(10 * 100vw / 1080);
      padding-left: calc(20 * 100vw / 1080);
      display: flex;
      align-items: center;
      font-size: calc(26 * 100vw / 1080);
      color: rgb(241, 12, 142);
      border-left: 1px dashed rgb(241, 12, 142);
    }

    /*  */
    .buy .buy_totalPrice {
      height: calc(130 * 100vw / 1080);
      padding-bottom: calc(30 * 100vw / 1080);
      align-items: flex-end;
      border-top: 1px solid #ddd;
      margin-bottom: 0;
    }

    .MOQ {
      margin-left: calc(15 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #666666;
      display: inline-block;
      width: calc(25* 1000vw / 1920);
      max-width: calc(25* 1000vw / 1920);
      width: calc(25* 1000vw / 1920);
      text-align: center;
    }

    .MOQ2 {
      margin-left: calc(15 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #666666;
      display: inline-block;
      width: calc(50* 1000vw / 1920);
      max-width: calc(50* 1000vw / 1920);
      text-align: center;
    }

    .buy .buy_totalPrice .buy_totalPrice_title {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #666666;
    }

    .buy .buy_totalPrice .totalPrice {
      margin-left: calc(123 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #666666;
    }

    .buy_button_pc {
      display: none;
    }

    .buy .buy_refund {
      margin-top: 0;
      padding-top: calc(63 * 100vw / 1080);
      padding-bottom: calc(30 * 100vw / 1080);
      border-top: 1px solid #ddd;
      flex-wrap: wrap;
    }

    .buy .buy_refund1 {
      margin-top: 0;
      padding: calc(30 * 100vw / 1080) 0;
      /* padding-bottom: calc(30 * 100vw / 1080); */
      border-top: 1px solid #ddd;
      flex-wrap: wrap;
    }

    .buy .buy_refund .buy_refund_title1,
    .buy .buy_refund1 .buy_refund_title1 {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #666666;
    }

    .buy .buy_refund .buy_refund_title2,
    .buy .buy_refund1 .buy_refund_title2 {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #666666;
      margin-left: calc(182 * 100vw / 1080);
      word-wrap: break-word;
    }

    .buy_refund_title3 {
      font-weight: bold;
      font-size: calc(40 * 100vw / 1080);
      color: #000;
      margin-left: calc(182 * 100vw / 1080);
    }

    .buy .buy_refund .buy_refund_url {
      margin-left: calc(313 * 100vw / 1080);
      margin-top: calc(34 * 100vw / 1080);
      /* width: calc(94 * 100vw / 1920); */
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      /* text-decoration: underline; */
      color: #13af87;
      line-height: calc(36 * 100vw / 1080);
      border-bottom: 1px solid #13af87;
    }

    .buy .buy_share_phone {
      display: block;
    }

    .buy .buy_share {
      margin-top: 0;
      padding: calc(60 * 100vw / 1080) 0;
      border-top: 1px solid #ddd;
    }

    .buy .buy_share .buy_share_title {
      font-size: calc(36 * 100vw / 1080);
    }

    .buy .buy_share .buy_share_loge {
      margin-left: calc(211 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
    }

    .buy .buy_share .buy_share_loge img {
      width: calc(75 * 100vw / 1080);
      height: calc(75 * 100vw / 1080);
      margin-right: calc(40 * 100vw / 1080);
    }

    .buy .small_pay .buy_share_loge {
      margin-left: calc(330 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      display: flex;
      align-items: center;
    }

    .buy .small_pay .buy_share_loge>div {
      width: calc(82 * 100vw / 1080);
      height: calc(69 * 100vw / 1080);
      margin-right: 5px !important;
    }

    .buy .small_pay .buy_share_loge>div>img {
      width: 100%;
      height: 100%;
    }

    .buy .buy_refunds {
      margin-top: 0;
      border-top: 1px solid #ddd;
      padding: calc(60 * 100vw / 1080) 0;
    }

    .buy .buy_refunds>p {
      font-weight: bold;
      font-size: calc(40 * 100vw / 1080);
      color: #000;
      display: flex;
    }

    .buy .buy_refunds>p>img {
      margin-right: calc(5 * 100vw / 1080);
    }

    .buy .buy_refunds>div {
      color: #333;
      font-size: calc(40 * 100vw / 1080);
      margin-top: calc(30 * 100vw / 1080);
      margin-left: calc(55 * 100vw / 1080);
    }

    .buy .buy_refunds>div>span {
      text-decoration: underline;
    }

    .buy .buy_samples {
      margin-top: 0;
      padding: calc(60 * 100vw / 1080) 0;
      border-top: 1px solid #ddd;
    }

    .buy .buy_samples .buy_samples_title {
      font-size: calc(36 * 100vw / 1080);
    }

    .buy .buy_samples .buy_samples_detail {
      width: calc(450 * 100vw / 1080);
      margin-left: calc(136 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      display: flex;
      align-items: center;
    }

    .buy .buy_samples .buy_samples_detail span {
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 500;
      color: #ff0000;
    }

    .buy .buy_customization {
      margin-top: 0;
      padding: calc(60 * 100vw / 1080) 0;
      border-top: 1px solid #ddd;
    }

    .buy .buy_customization .buy_customization_title {
      font-size: calc(36 * 100vw / 1080);
    }

    .buy .buy_customization .buy_customization_detail {
      /* margin-left: calc(211 * 100vw / 1080); */
      width: calc(700 * 100vw / 1080);
      margin-left: calc(136 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
    }

    .buy_customization_detail>span {
      margin: 0 calc(30 * 100vw / 1080);
    }

    .buy_customization_detail .edit_div {
      display: flex;
      align-items: center;
    }

    .buy_customization_detail .edit_div>input {
      width: calc(300 * 100vw / 1080);
      height: calc(80 * 100vw / 1080);
      border: 1px solid #ddd;
      margin-right: calc(30 * 100vw / 1080);
    }

    /* .buy .buy_share .buy_share_loge img:first-child {
      width: calc(20 * 100vw / 1080);
      margin-right: calc(50 * 100vw / 1080);
    } */
    .buy_nav_phone {
      display: flex;
      border-top: 1px solid #ddd;
      padding: calc(60 * 100vw / 1080) 0;
      font-size: calc(36 * 100vw / 1080);
      color: #666666;
      justify-content: flex-start !important;
    }

    .buy_nav_phone ul {
      flex: 1;
      flex-wrap: wrap;
      margin-left: calc(211 * 100vw / 1080);
      padding-left: calc(130 * 100vw / 1080);
    }

    .buy_nav_phone ul li {
      /* padding: 0 calc(20 * 100vw / 1080);
      margin-right: calc(30 * 100vw / 1080);
      margin-bottom: calc(20 * 100vw / 1080); */
      margin: 0 calc(30 * 100vw / 1080) calc(20 * 100vw / 1080) calc(30 * 100vw / 1080);
      padding-bottom: calc(10 * 100vw / 1080);
      border-bottom: 1px solid #13af87;
      max-width: 100%;
      word-break: break-word;
    }

    .description {
      width: 100%;
      padding-bottom: 0;
      background-color: #fff;
    }

    .description .description_title {
      margin-left: calc(40 * 100vw / 1080);
      width: calc(280 * 100vw / 1080);
      height: calc(145 * 100vw / 1080);
      white-space: nowrap;
      line-height: calc(145 * 100vw / 1080);
      border-bottom: calc(6 * 100vw / 1080) solid rgba(19, 175, 135, 1);
      font-weight: bolder;
      font-size: calc(48 * 100vw / 1080);
    }

    .description .description_main {
      border-top: 1px solid #ddd;
      padding: calc(50 * 100vw / 1080) calc(40 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: 400;
      color: #666666;
      line-height: calc(48 * 100vw / 1080);
      word-wrap: break-word;
    }

    .buy_button_phone {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-top: calc(5 * 100vw / 1080) solid #ddd;
      padding: calc(23 * 100vw / 1080) 50px;
      padding-bottom: 0px !important;
    }

    .buy_button_phone .add {
      margin-bottom: 10px;
      /* margin-right: calc(180 * 100vw / 1080) !important; */
    }

    .buy_button_phone .add,
    .buy_button_phone .addcart {
      margin-right: calc(20 * 100vw / 1080);
      color: #13af87;
      background: #d0fff3;
    }

    .buy_button_phone .buyNow,
    .buy_button_phone .addcart,
    .buy_button_phone .add {
      width: calc(300 * 100vw / 1080);
      height: calc(80 * 100vw / 1080);
      border-radius: calc(40 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
      font-family: Roboto;
      font-weight: bold;
      border: 1px solid #13af87;
    }

    .buy_button_phone .buyNow {
      background: #d0fff3;
      color: #13af87;
    }

    .buy_shipping {
      width: calc(700 * 100vw / 1080);
      margin-left: calc(136 * 100vw / 1080);
    }

    .buy_shipping1 {
      width: calc(700 * 100vw / 1080);
      margin-left: calc(100 * 100vw / 1080);
      /* line-height: 50px; */
    }

    .buy_shipping span,
    .buy_shipping1 span {
      display: inline-block;
      width: 50%;
      margin-bottom: calc(10 * 100vw / 1080);
      font-size: calc(36 * 100vw / 1080);
    }
  }
</style>

<style>
  .buy_refund .el-radio__label {
    display: inline-block;
  }

  .buy_refund .el-radio-group {
    width: calc(500 * 100vw / 1920);
    margin-left: calc(50 * 100vw / 1920);
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    font-size: calc(24 * 100vw / 1920);
  }

  .buy_refund .el-radio {
    width: 50%;
    margin-right: 0;
    margin-bottom: calc(10 * 100vw / 1920);
  }

  .description_main p,
  .policy p {
    width: 100%;
    margin-bottom: calc(20 * 100vw / 1920);
    text-indent: calc(32 * 100vw / 1920);
  }

  .description_main p img,
  .policy p img {
    max-width: 97%;
    max-height: 100%;
  }

  @media screen and (max-width: 1080px) {

    .description_main p,
    .policy p {
      width: 100%;
      margin-bottom: calc(10 * 100vw / 1080);
      font-size: calc(40 * 100vw / 1080);
    }

    .buy_refund .el-radio__inner {
      width: calc(36 * 100vw / 1080);
      height: calc(36 * 100vw / 1080);
    }

    .buy_refund .el-radio__label {
      font-size: calc(36 * 100vw / 1080);
      padding-left: calc(5 * 100vw / 1080);
    }

    .buy_refund .el-radio-group {
      width: calc(700 * 100vw / 1080);
      margin-left: calc(136 * 100vw / 1080);
    }

    .buy_refund .el-radio {
      margin-right: 0;
      margin-bottom: calc(10 * 100vw / 1080);
    }
  }
</style>